<template lang="pug">
  div.h-100
    .h-100(v-if='isLoadingLease || isUpdatingLease || isDeletingLease')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        div(style="margin-top: 7rem")
          |  Chargement des détails du bail...
    .h-100(v-else)
      .sidebar-header.py-1.px-2
        div
          h3 {{lease.label}}
          small {{lease.leaseType ? lease.leaseType.label + ' -' : ''}} Du {{formatDate(lease.startDate)}} au {{formatDate(lease.endDate)}}
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      vs-divider.m-0
      .d-flex.justify-content-between.sidebar-action.py-05.px-2.w-100
        .d-flex(v-if='!archived')
        .d-flex.justify-content-between.w-100
          b-button.btn-icon(v-if='archived' variant='warning' @click='restoreLeaseLocal(lease.id, lease.label)')
            feather-icon(icon='ArrowDownIcon')
            | Restaurer
          b-button-group(v-else)
            b-button(style="border-left:1px solid #ccc !important;" class="btn-icon d-flex" variant="primary" @click="showLease(lease.id)")
              feather-icon(icon="EyeIcon")
              span(style="margin-left:3px") Gérer
          b-dropdown.dropdown-icon-wrapper(variant='outline-primary' left)
            template(#button-content)
              feather-icon.align-middle(icon='MoreHorizontalIcon')
            //- b-dropdown-item(v-if='archived' @click.stop='deleteLeaseLocal(lease.id, lease.label)')
            //-   | Supprimer d&eacute;finitivement
            b-dropdown-item(@click.stop='archiveLeaseLocal(lease.id, lease.label)')
              | Archiver
            b-dropdown-item(@click.stop='duplicateLeaseLocal')
              | Dupliquer
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style='padding: 0 !important; height: 100% !important;' cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                span Détails
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                span Notes
                feather-icon.align-middle.ml-50(v-if='lease.internalNote' icon='MessageCircleIcon')
      .sidebar-content.with-tabs
        div(v-if="tabActive == 'details'")
          div(v-if='lease.landlordId')
            .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(lease.landlordId, 'bailleur')" style="flex: 1;")
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  span.text-uppercase {{ lease.landlordName }}
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Bailleur
            div
              span {{lease.landlordAddress}}
                br
              span(v-if="lease.landlordAddressComplement") {{lease.landlordAddressComplement}}
                br
              span {{lease.landlordZipCode}} {{lease.landlordCity}}
            vs-divider
          div(v-if='lease.tenantId')
            .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(lease.landlordId, 'bailleur')" style="flex: 1;")
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  span.text-uppercase {{ lease.tenantName }}
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Locataire
            div
              span {{lease.tenantAddress}}
                br
              span(v-if="lease.tenantAddressComplement") {{lease.tenantAddressComplement}}
                br
              span {{lease.tenantZipCode}} {{lease.tenantCity}}
            vs-divider
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Données financières
          .w-full.d-flex.mt-1.flex-column
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Type de bail
                span {{lease.leaseType ? lease.leaseType.label : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Périodicité
                span {{lease.periodicity ? lease.periodicity.label : "N/A"}} 
            .table-financial-data.column.w-100.d-flex.justify-content-between.text-primary
                span.fw-500 Loyer
                span.fw-500 {{lease.rentHcHt ? formatCurrency(lease.rentHcHt) + ' HT' : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between(v-if="lease.isRentReview")
                span Type de révision
                span {{lease.isSubscriptReferenceRentReview ? "Indice de référence" : "Pourcentage"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between(v-if="lease.isRentReview")
                span Type d'indice
                span {{lease.rentReviewSubScriptType ? lease.rentReviewSubScriptType : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between(v-if="lease.isRentReview")
                span Indice de révision
                span {{lease.rentReviewSubScript ? lease.rentReviewSubScript : "N/A"}}
          vs-divider
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Adresse du bien
          .w-full.d-flex.justify-content-between.mt-1
            .tenant-address.d-flex.flex-column
              span {{lease.propertyAddress}}
              span(v-if="lease.propertyAddressComplement") {{lease.propertyAddressComplement}}
              span {{lease.propertyZipCode}} {{lease.propertyCity}}
          .w-full.d-flex.justify-content-between.mt-1(v-if="lease.propertyLatitude && lease.propertyLatitude>0")
            GmapMap.w-100(ref="gmap" class="card-map" :center="{lat:lease.propertyLatitude, lng:lease.propertyLongitude}" :zoom="15" style=" height: 220px")
              GmapMarker(:position="{lat:lease.propertyLatitude, lng:lease.propertyLongitude}" :clickable="true" :draggable="true" @click="center={lat:lease.propertyLatitude, lng:lease.propertyLongitude}")
        div.h-100(v-if="tabActive == 'notes'")
          quill-editor#text-area-designation(v-model="lease.internalNote" toolbar="essential" @blur="updateLeaseWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      archived: false,
      lease: {},
      tabActive: "details",
      editorOption: {
        placeholder: "Ecrivez une note interne lié à cette affaure ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingLease",
      "isLoadingLease",
      "isDeletingLease",
      "affair",
    ]),
    loadingMsg: {
      get() {
        return this.$store.getters["loadingMsg"];
      },
      set(value) {
        return this.$store.commit("SET_LOADING_MSG", value);
      },
    },
    isLoadingDuplicatingLease: {
      get() {
        return this.$store.getters["isLoadingDuplicatingLease"];
      },
      set(value) {
        return this.$store.commit("SET_IS_LOADING_DUPLICATING_LEASE", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "updateLease",
      "updateAffair",
      "deleteLeases",
      "isArchivingLease",
      "archiveLease",
      "getLeaseById",
      "getLeasesByAffairId",
      "getLeaseArchived",
      "restoreLease",
      "duplicateLease",
    ]),
    formatCurrency,
    formatDate,
    duplicateLeaseLocal() {
      this.loadingMsg = "Duplication en cours...";
      this.isLoadingDuplicatingLease = true;
      this.duplicateLease({
        leaseId: this.lease.id,
      }).then(async (res) => {
        this.closeSidebar();
        this.isLoadingDuplicatingLease = false;
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "edit-lease",
          params: {
            id: res.data.id,
            title: "Edit : " + res.data.label,
            tips: "Editer le bail : " + res.data.label,
            routeOrigine: "leases",
          },
        });
      });
    },
    getLeaseArchivedDetails(id, archived) {
      this.archived = archived;
      if (id != "") {
        this.getLeaseArchived({ leaseId: id }).then((res) => {
          this.lease = res;
        });
      } else {
        this.lease = {};
      }
    },
    updateLeaseWithoutLoading() {
      this.updateAffair({
        affair: { ...this.affair, internalNote: this.lease.internalNote },
        updateState: false,
        route: "affair",
      });
    },
    getLeaseDetails(id) {
      this.archived = false;
      if (id != "") {
        this.getLeaseById({
          leaseId: id,
        }).then((res) => {
          this.lease = res;
        });
      } else {
        this.lease = {};
      }
    },
    getLeaseDetailsByAffairId(id) {
      this.archived = false;
      if (id != "") {
        this.getLeasesByAffairId({ affairId: id }).then((res) => {
          this.lease = res;
        });
      } else {
        this.lease = {};
      }
    },
    async showLease(id) {
      this.closeSidebar();
      this.$router.push({
        name: "lease",
        params: {
          id: id,
          title: "Bail : " + this.lease.label,
          tips: "Bail : " + this.lease.label,
          routeOrigine: "leases",
        },
      });
    },
    async editLease(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-lease",
        params: {
          id: id,
          title: "Edit : " + this.lease.label,
          tips: "Editer le bail : " + this.lease.label,
          routeOrigine: "leases",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " +
            (this.lease.company.name
              ? this.lease.company.name
              : this.lease.company.firstName +
                " " +
                this.lease.company.lastName),
          tips:
            "Editer l'entreprise : " +
            (this.lease.company.name
              ? this.lease.company.name
              : this.lease.company.firstName +
                " " +
                this.lease.company.lastName),
          routeOrigine: "leases",
        },
      });
    },
    archiveLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce bail en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le bail "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveLease(id).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    restoreLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le bail "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreLease(id).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer le bail "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteLeases({
              leaseIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    async updateDocumentWithoutLoading() {
      this.updateLease({ credit: this.lease, updateState: false }).then(
        (res) => {
          this.$emit("refreshRow", res);
        }
      );
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
  },
};
</script>

<style>
.no-border-radius-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.no-border-radius-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-right {
  border-right: none !important;
}
.h3 {
  margin: 0;
}
.flex-column > .table-financial-data {
  padding: 5px 10px 5px 10px;
}
.flex-column > .table-financial-data:nth-child(odd) {
  background-color: #eef1f6;
}
</style>
