<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" :mapModeButton="mapModeButton" :mapMode="mapMode" @updateMapMode="updateMapMode" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      #nav-sticky-horizontal.content-scrollable-sticky-inner(style='padding: 0 !important;width: 100%; height: 100% !important')
        ul.d-flex.justify-content-start(style="overflow-x: auto; overflow-y: hidden;")
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span.text-nowrap Toutes les affaires ({{ getCountDataByStatus("all") }})
          li(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == status.value ? 'current' : ''" @click="tabActive = status.value")
              span.text-nowrap {{ status.label }} ({{ getCountDataByStatus(status.value) }})
    .content-grid-with-tabs.d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingAffairsList || isLoadingAffairsListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des affaires...
      div(v-else class="row w-100" style="margin:0px !important")
        sync-grid#overviewgrid(ref="overviewgrid" :uid="uid" :key="gridKey" :class="mapMode ? 'col-12 col-lg-6 d-none d-lg-block' : 'col-12'" style="padding: 0px !important;" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton")
        GmapMap.card-map.col-12.col-lg-6.order-sm-1(v-if='mapMode' ref='gmap' :zoom='6' :center='{lat:47, lng:2}' style='width: 50%; height: 100%;')
          GmapMarker(:key='index' v-for='(m, index) in dataFiltered.filter(elem=> elem.latitude && elem.longitude && elem.latitude!==0 && elem.longitude!==0)' :position='{lat:m.latitude, lng:m.longitude}' :clickable='true' :draggable='true')
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpenSidebarAffair" ref="sidebarAffair" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenSidebarAffair")
      sidebar-affair-content(ref="sidebarAffairContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebarAffair")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpenSidebarLease" ref="sidebarLease" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenSidebarLease")
      sidebar-lease-content(ref="sidebarLeaseContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebarLease")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarAffairContent from "@/components/directory/affair/SidebarAffairContent";
import SidebarLeaseContent from "@/components/billing/lease/SidebarLeaseContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";
import { affairStatus } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    SidebarAffairContent,
    SidebarLeaseContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpenSidebarAffair: false,
      isOpenSidebarLease: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "affair",
      uid: "affairs",
      statusList: affairStatus,
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      gridKey: 1,

      dateRanger: false,
      mapMode: false,
      mapModeButton: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      archiveMode: true,
      searchPlaceholder: "Rechercher une affaire",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "color",
        headerText: "Couleur",
        width: 60,
        maxWidth: 60,
        allowFiltering: false,
        allowSorting: true,
        showInColumnChooser: true,
        template: "colorTemplate",
        visible: true,
        unavailableExport: true,
      },
      {
        type: "string",
        field: "code",
        headerText: "Code",
        width: 100,
        maxWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        direction: "Descending",
      },
      {
        type: "string",
        field: "name",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Description",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        filter: { type: "Menu", operator: "contains" },
        template: "stripHtmlTemplate",
      },
      {
        type: "string",
        field: "company.name",
        headerText: "Client lié",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        visible: true,
      },
      {
        type: "string",
        field: "affairTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "affairStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "parentAffairLabel",
        format: "formatNumber",
        headerText: "Affaire parente",
        width: 150,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "startDate",
        headerText: "Date de début",
        width: 125,
        maxWidth: 125,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "endDate",
        headerText: "Date de fin",
        width: 125,
        maxWidth: 125,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "affairsList",
      "affairsListArchived",
      "isLoadingAffairsList",
      "isLoadingAffairsListArchived",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.archive
          ? this.$store.getters[this.name + "sListArchived"].filter((elem) =>
              elem[this.name + "Tags"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            )
          : this.$store.getters[this.name + "sList"].filter((elem) =>
              elem[this.name + "Tags"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            );
      } else {
        return this.archive
          ? this.$store.getters[this.name + "sListArchived"]
          : this.$store.getters[this.name + "sList"];
      }
    },
    dataFiltered() {
      const listToFilter = this.archive
        ? this.$store.getters[this.name + "sListArchived"]
        : this.$store.getters[this.name + "sList"];

      if (this.statusList) {
        if (this.tabActive == "all") {
          return listToFilter.filter((elem) =>
            this.tagsSelected.length
              ? elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
              : true
          );
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          return listToFilter.filter(
            (elem) =>
              (this.tagsSelected.length
                ? elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  )
                : true) &&
              (statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === this.tabActive)
          );
        }
      } else {
        return [];
      }
    },
  },
  async created() {
    await this.getCollaborators({});
    await this.getAffairs({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  mounted() {
    this.viewMap = false;
  },
  methods: {
    ...mapActions(["getAffairs", "getCollaborators", "getAffairsArchived"]),
    formatCurrency,
    setColumnsByObject,
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-affair" });
    },
    updateMapMode(val) {
      this.mapMode = val;
      this.getAffairs({});
      this.getAffairsArchived({});
      this.$nextTick(() => {
        this.gridKey++;
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getAffairsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getAffairs({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      if (
        event.rowData.affairType &&
        event.rowData.affairType.label == "Bail"
      ) {
        this.isOpenSidebarLease = true;
        if (this.archive) {
          this.$refs["sidebarLeaseContent"].getLeaseArchivedDetails(
            event.rowData.id,
            1
          );
        } else {
          this.$refs["sidebarLeaseContent"].getLeaseDetailsByAffairId(
            event.rowData.id
          );
        }
        this.$refs.sidebarLease.toggle();
      } else {
        this.isOpenSidebarAffair = true;
        if (this.archive) {
          this.$refs["sidebarAffairContent"].getAffairArchivedDetails(
            event.rowData.id,
            1
          );
          this.$refs.sidebarAffair.toggle();
        } else {
          this.$refs["sidebarAffairContent"].getAffairDetails(event.rowData.id);
          this.$refs.sidebarAffair.toggle();
        }
      }
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebarAffair() {
      this.$refs.sidebarAffair.hide();
    },
    closeSidebarLease() {
      this.$refs.sidebarLease.hide();
    },
  },
};
</script>
